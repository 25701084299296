exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policies-pages-cookie-policy-js": () => import("./../../../src/pages/PoliciesPages/cookie-policy.js" /* webpackChunkName: "component---src-pages-policies-pages-cookie-policy-js" */),
  "component---src-pages-policies-pages-payment-policy-js": () => import("./../../../src/pages/PoliciesPages/payment-policy.js" /* webpackChunkName: "component---src-pages-policies-pages-payment-policy-js" */),
  "component---src-pages-policies-pages-privacy-policy-js": () => import("./../../../src/pages/PoliciesPages/privacy-policy.js" /* webpackChunkName: "component---src-pages-policies-pages-privacy-policy-js" */),
  "component---src-pages-policies-pages-refund-policy-js": () => import("./../../../src/pages/PoliciesPages/refund-policy.js" /* webpackChunkName: "component---src-pages-policies-pages-refund-policy-js" */),
  "component---src-pages-policies-pages-shipping-policy-js": () => import("./../../../src/pages/PoliciesPages/shipping-policy.js" /* webpackChunkName: "component---src-pages-policies-pages-shipping-policy-js" */),
  "component---src-pages-policies-pages-terms-and-conditions-policy-js": () => import("./../../../src/pages/PoliciesPages/terms-and-conditions-policy.js" /* webpackChunkName: "component---src-pages-policies-pages-terms-and-conditions-policy-js" */),
  "component---src-pages-products-products-js": () => import("./../../../src/pages/Products/products.js" /* webpackChunkName: "component---src-pages-products-products-js" */),
  "component---src-pages-reseller-js": () => import("./../../../src/pages/reseller.js" /* webpackChunkName: "component---src-pages-reseller-js" */)
}

