import * as React from "react"

import "./src/styles/global.css"
import "./src/styles.scss"
import "./src/i18n"
import "./src/route"

export const onClientEntry = () => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css";
    document.head.appendChild(link);
  };
