import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      home: "Home",
      about: 
          {
          about: "About Us",
          aboutContent: "This is a test about text"
          },
      contact: "Contact",
    },
  },
  nl: {
    translation: {
      home: "Huis",
      about: 
        {
          about: "Over ons",
          aboutContent: "This is a test about text"
        },
      contact: "Contact",
    },
  },
  es: {
    translation: {
      home: "Inicio",
      about:
      {
        about: "Sobre nosotros",
        aboutContent: "This is a test about text"
      },
      contact: "Contacto",
    },
  },
  'pt-br': {
    translation: {
      home: "Início",
      about:
      {
        about: "Sobre nós",
        aboutContent: "This is a test about text"
      },
      contact: "Contato",
    },
  },
  de: {
    translation: {
      home: "Startseite",
      about:
      {
        about: "Über uns",
        aboutContent: "This is a test about text"
      },
      contact: "Kontakt",
    },
  },
  fr: {
    translation: {
      home: "Accueil",
      about:
      {
        about: "À propos",
        aboutContent: "This is a test about text"
      },
      contact: "Contact",
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
