const routes = {
  en: {
    home: '/',
    about: '/about-us/',
    contact: '/contact',
    products: '/products/:productId',
    privacyPolicy: '/privacy-policy',
    termAndConditions: '/terms-and-conditions',
    cookiePolicy: '/cookie-policy',
    paymentPolicy: '/payment-policy',
    refundPolicy: '/refund-policy',
    shippingPolicy: '/shipping-policy',
    becomeReseller : '/become-reseller',
    faq: '/faq'
  },
  nl: {
    home: '/nl',
    about: '/nl/over-ons/',
    contact: '/nl/contact',
    products: '/nl/products/:productId',
    cookiePolicy: '/nl/cookie-policy',
    privacyPolicy: '/nl/privacy-policy',
    termAndConditions: '/nl/terms-and-conditions',
    cookiePolicy: '/nl/cookie-policy',
    paymentPolicy: '/nl/payment-policy',
    refundPolicy: '/nl/refund-policy',
    shippingPolicy: '/nl/shipping-policy',
    becomeReseller : '/nl/become-reseller',
    faq: '/nl/faq'

  },
  es: {
    home: '/es',
    about: '/es/sobre-nosotros/',
    contact: '/es/contacto',
    products: '/es/products/:productId',
    cookiePolicy: '/es/cookie-policy',
    privacyPolicy: '/es/privacy-policy',
    termAndConditions: '/es/terms-and-conditions',
    cookiePolicy: '/es/cookie-policy',
    paymentPolicy: '/es/payment-policy',
    refundPolicy: '/es/refund-policy',
    shippingPolicy: '/es/shipping-policy',
    becomeReseller : '/es/become-reseller',
    faq: '/ed/faq'
  },
  'pt-br': {
    home: '/pt-br',
    about: '/pt-br/sobre-nos/',
    contact: '/pt-br/contacto',
    products: '/pt-br/products/:productId',
    cookiePolicy: '/pt-br/cookie-policy',
    privacyPolicy: '/pt-br/privacy-policy',
    termAndConditions: '/pt-br/terms-and-conditions',
    cookiePolicy: '/pt-br/cookie-policy',
    paymentPolicy: '/pt-br/payment-policy',
    refundPolicy: '/pt-br/refund-policy',
    shippingPolicy: '/pt-br/shipping-policy',
    becomeReseller : '/pt-br/become-reseller',
    faq: '/pt-br/faq'
  },
  de: {
    home: '/de',
    about: '/de/über-uns/',
    contact: '/de/kontakt',
    products: '/de/products/:productId',
    cookiePolicy: '/de/cookie-policy',
    privacyPolicy: '/de/privacy-policy',
    termAndConditions: '/de/terms-and-conditions',
    cookiePolicy: '/de/cookie-policy',
    paymentPolicy: '/de/payment-policy',
    refundPolicy: '/de/refund-policy',
    shippingPolicy: '/de/shipping-policy',
    becomeReseller : '/de/become-reseller',
    faq: '/de/faq'

  },
  fr: {
    home: '/fr',
    about: '/fr/a-propos/',
    contact: '/fr/contact',
    products: '/fr/products/:productId',
    cookiePolicy: '/fr/cookie-policy',
    privacyPolicy: '/fr/privacy-policy',
    termAndConditions: '/fr/terms-and-conditions',
    cookiePolicy: '/fr/cookie-policy',
    paymentPolicy: '/fr/payment-policy',
    refundPolicy: '/fr/refund-policy',
    shippingPolicy: '/fr/shipping-policy',
    becomeReseller : '/fr/become-reseller',
    faq: '/fr/faq'
  },
};

export default routes;
